<template>
<div class="templates">
   <div class="Payment" v-cloak> 
    <div class="top" v-for='item in details'>
      <div class="left">
           <img :src="item.course_image" alt="">
      </div>
      <div class="right">
        <p>{{item.course_name}}</p>
        <span class="money">￥{{item.discount_price}}</span>
      </div>
    </div>
    <!-- <div class="solided"></div>
    <div class="header">
      <div class="headers">
        <img  src="../../assets/hyvip.png" alt="">
        <span>开通会员立省***元</span>
      </div>
      <div class="headerlist">
        立即开通
      </div>
    </div> -->
    <div class="solided"></div>
    <div>
        <div class="boxfind"> 
          <span class="boxleft">使用优惠券</span>
          <span class="boxright" >满1000减200</span>
          <img src="../../assets/zfyhqjt.png" alt="">
        </div>
        <div class="xian"></div>
        <!-- <div class="boxfind"> 
          <span class="boxleft">使用研值抵扣</span>
          <span class="boxrightlist">
            <img src="../../assets/yjjxy.png" alt="" v-if='listright' @click='rightlist'>
             <img src="../../assets/yjjxz.png" alt="" v-else @click='rightlist'>
          </span>
        </div>
        <div class="boxuser"> 
          <span class="boxleft">所需研值</span>
          <span class="boxright">-500</span>
        </div>
        <div class="boxuser"> 
          <span class="boxleft">抵扣金额</span>
          <span class="boxright" style="color:red">-200</span>
        </div> -->
    </div>

    <div class="solided"></div>
    <div class="pay">
      <div class="wx" @click='wxs'>  <span>微信</span>   <img src="../../assets/zfxz.png" alt="" class="xuanze" v-if='wx'>  <img src="../../assets/zfwxz.png" alt="" class="xuanze" v-if='wx==false'> <img src="../../assets/zfwx.png" alt="" class="tb"></div>
      <div class="xian"></div>
      <div class="zfb" @click='zfbs'> <span>支付宝</span> <img src="../../assets/zfxz.png" alt="" class="xuanze" v-if='zfb'>  <img src="../../assets/zfwxz.png" alt="" class="xuanze" v-if='zfb==false'><img src="../../assets/zfzfb.png" alt="" class="tb"> </div>
    </div>

    <div class="agreement">
            <img src="../../assets/dlwxxy.png" alt="" v-if='img' @click='imgs'>
             <img src="../../assets/dlxdxy.png" alt="" v-else @click='imgs'>
            <span>阅读并同意<a style="color:blue">《课程服务协议》</a></span> 
          </div>
    <div class="footer">
      <span>￥{{discount_price}}</span>
       <div class="btn"  @click='payments'>立即支付</div>
    </div>
  </div>
</div>
 
</template>
<script>
import request from "../../plugin/request";
import Cookies from 'js-cookie';
export default {
  data() {
    return {
      zfb:false,
      wx:true,
      img:false,
      listright:false,
      details:'',
      content:'选择优惠券',
      pay_type:0,
      use_coupon:0,
      discount_price:'',
      // 优惠券参数
      coupon:[{
        id:'',
        coupon_id:'',
        coupon_code:''
      }],
      id:'',
      ids:'',
      JointReport_id:'',
      group_com_id:'',
      group:'',
      url:'',
      share_id:''
    };
  },
  mounted(){
    let currentQuery = this.$route.query;
    console.log(this.$route.query)
    this.url=currentQuery.url
     this.share_id=currentQuery.share_id
    this.group=currentQuery.group;
    this.group_com_id=currentQuery.group_com_id;
    this.ids = currentQuery.id;
    this.discount_price=currentQuery.discount_price
    this.content = currentQuery.content;
    this.coupon[0].coupon_id=currentQuery.coupon_id
    this.coupon[0].coupon_code=currentQuery.code
    this.coupon[0].id=currentQuery.yhq_id
    this.use_coupon=currentQuery.use_coupon
    this.JointReport_id=currentQuery.JointReport_id
     this.setToken()
     this.$nextTick(() => {
      this.detail()
      
    });

  },
  methods:{
imgs() {
       this.img=!this.img
    },
    rightlist() {
       this.listright=!this.listright
    },
    wxs () {
      this.wx=true
      this.zfb=false
      this.pay_type=0
      console.log(this.pay_type)
    },
    zfbs () {
      this.wx=false
      this.zfb=true
       this.pay_type=1
       console.log(this.pay_type)
    },
    setToken(token) {
       var tokens = localStorage.getItem('token');
       console.log(tokens)
       if(tokens!=null) {
          return Cookies.set('Token', tokens)
       } else {
           return Cookies.set('Token', token)
       }
    },
  async detail() {
    // console.log(JSON.stringify()this.id)
    if(this.use_coupon==undefined) {
      this.use_coupon=0
    } else {
      this.use_coupon=1
    }
   
      const data = await request.post('/app/order/orderCourse',{
         course_id:this.ids.split(',')
      })
      this.details=data.data
      console.log(this.JointReport_id)
      // if(data.code!='200') {
      //     this.$router.push({ path:'/login?url='+this.url+'&&id='+this.ids})
      // } 
    },
    Coupon() {
       this.$router.push({ path:'/Coupon?id='+this.ids+'&JointReport_id='+this.JointReport_id})
    },
    payments() {
      console.log(this.group)
      if(this.group==0) {
        this.payment()
      } else  {
        this.groupWork()

      }
    },
  // 普通课程+组合支付
  async payment() {
    let parame=''

    if(this.use_coupon==0)  {
      parame={
         pay_type:this.pay_type,
         channel:'h5',
         order_type:0,
         use_coupon:this.use_coupon,
         course_id:this.ids.split(','),
         share_id:this.share_id
        }
    } else {
       parame={
         pay_type:this.pay_type,
         channel:'h5',
         order_type:0,
         use_coupon:this.use_coupon,
         coupon:this.coupon,
         course_id:this.ids.split(','),
         share_id:this.share_id
        }
    }
    if(this.JointReport_id!=undefined) {
      parame.market_id=this.JointReport_id
    }
      console.log(parame)
      const data =await request.post('/app/order/createOrder',parame)
      console.log(data)
      if(data.code==200)  {
        this.orderPay(data.data.id)
      } else if(data.code==505){
          // Toast('请先登录！');  
          this.$router.push({ path:'/login?url=Payment&&id='+this.ids+'&share_id='+this.share_id})
      }
    },
  async orderPay(id) {
    const data=await request.post('/app/order/orderPay',{
      order_id:id
    })
    if(this.pay_type==1)  {    
    this.url=data.data.ali 
     window.location.href= this.url
     console.log(this.url)
    } 
     if(this.pay_type==0) {
    this.urls=data.data.wx.mweb_url
     window.location.href= this.urls
    }
  },
  // 拼团课程
  async groupWork() {
    const data=await request.post('/app/group/groupWork',{
      pay_type:this.pay_type,
      channel:'h5',
      course_id:this.ids,
      remark_cus:'拼团支付',
      group_com_id:this.group_com_id
    })
    console.log(data)
    this.groupOrderPay(data.data)
  },
  async groupOrderPay(order_id) {
    const data=await  request.post('/app/group/groupOrderPay',{
      order_id:order_id,
      pay_type:this.pay_type
    })
  
    if(this.pay_type==1)  {   
    this.url=data.data.ali
     window.location.href= this.url
      console.log(this.url)
    }
     if(this.pay_type==0) {
        this.urls=data.data.wx.mweb_url
        window.location.href= this.urls
    }
  }
  }
};
</script>
<style lang="less" scoped>

.top {
  width: 9.48rem;
  height: 3.11rem;
  // background: url(../../assets/zftop.png);
  background-size: 100% 100%;
  margin-top:.27rem;    
  margin-left: .27rem;
  margin-bottom:.17rem;


  .left {
    float: left;
    width: 3.47rem;
    height: 2.29rem;
    border-radius: .27rem;
    overflow: hidden;
    margin-top:.33rem;
    margin-left:.4rem;
    margin-right: .41rem;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .right {
    width: 5.2rem;
    float: left;
    margin-top:.13rem;
    position: relative;
    height: 100%;
    padding-right: .47rem;
    box-sizing: border-box;
    
   p {
     font-size: .37rem;
     font-weight: 400;
   }
   .money {
     font-size: .37rem;
     font-weight: 400;
     color: #FF4A26;
     position: absolute;
     right: .49rem;
     bottom:.49rem
    

   }
  }
}
.header{
   width: 9.45rem;
   position: relative;
   padding: 0.2rem;
   height: 1rem;
  .headers{
  
     color: #2371FF;
    height: 0.8rem;
    background-size: 100% 100%;
    margin-left: 0.32rem;
    // margin-top: 0.23rem;
    background-color: #D6E4FC;
    font-size: 12px;
    border-radius: 0.2rem;
    img{
      height: 0.5rem;
      width: 0.6rem;
      float: left;
      margin-top: 0.17rem;
    }
    span{
      height: 0.8rem;
      display: inline-block;
      line-height: 0.8rem;
      
    }
}
.headerlist{
  position: absolute;
  // float:right;
    right: 43px;
    font-size: 12px;
    top: 0.3rem;
    border: 1px solid #2371FF ;
    width: 1.5rem;
    height: 0.5rem;
    line-height: 0.5rem;
    border-radius: 0.5rem;
    text-align: center;
}
}
.solided{
    width: 100%;
    height: 0.2rem;
    background-color: #EBEFF2;
}
.coupon {
  width: 9.37rem;
  height: 1.19rem;
  background: url(../../assets/zfyhq.png);
  background-size: 100% 100%;
  margin-left: .32rem;
  p {
    margin:0;
    padding:0;
    height: 100%;
    line-height:1.19rem ;
  }
  span {
    display: inline-block;
  }

  .left {
     float: left;
     font-size: .37rem;
      margin-left: .63rem;
  }

  .right {
    font-size: .33rem;
    color: #FF5232;
    float: right;
    margin-right: .29rem;
  }

  img {
    width: .23rem;
    height: .43rem;
    float: right;
    margin-top:.37rem;
    margin-right: .49rem
  }
}
.boxfind{
  height: 0.8rem;
  line-height: 0.8rem;
  padding:0.3rem 0.6rem ;
  position: relative;
  .boxleft{
    float: left;
    color: #252525;
    font-size: 0.4rem;
  }
  .boxright{
    float:right;
    margin-right: 1rem;
    color: #FF4A26;
    font-size: 0.3rem;
  }
  img{
    width: 0.3rem;
    height: 0.3rem;
    position:absolute;
    margin-right: -80px;
    margin-top: 0.2rem;
    right: 120px;
  }
}
.boxrightlist{
   float:right;
    margin-right: 1rem;
    color: #FF4A26;
    font-size: 0.3rem;
  
  img{
    width: 0.8rem;
    height: 0.4rem;
    position:absolute;
    margin-right: -80px;
    margin-top: 0.2rem;
    right: 3.5rem;
  }
}
.boxuser{
  height: 0.8rem;
  line-height: 0.8rem;
  padding: 0.3rem 0.6rem;
  .boxleft{
    float: left;
    color: #B9B9B9;
    font-size: 0.2rem;
  }
  .boxright{
    float:right;
    margin-right: 1rem;
    color: #B9B9B9;
    font-size: 0.3rem;
  }
  img{
    width: 0.3rem;
    height: 0.3rem;
    float: right;
    margin-right: -80px;
    margin-top: 0.2rem;
  }
}
.xian  {
    width:8.5rem;
    height: .01rem;
    background: #D6E4FC;
    margin-left: .61rem;
   }
.pay {
  width: 9.37rem;
  height: 2.36rem;
  // background: url(../../assets/zfzf.png);
  background-size: 100% 100%;
  margin-left: .32rem;
  margin-top:.23rem;
   .xian  {
    width:7.73rem;
    height: .01rem;
    background: #D6E4FC;
    margin-left: .61rem;
   }

  div {
   font-size: .37rem;
   height: 50%;
   line-height: 1.15rem;
   padding-left:.63rem;

   .tb {
     width: .59rem;
     height: .59rem;
     float: right;
     margin-top:.4rem;
      margin-right:.41rem
   }

   .xuanze {
     width: .41rem;
     height: .41rem;
      float: right;
      margin-top:.48rem;
      margin-right:.49rem
   }

  }

  .wx {
     line-height: 1.5rem;
  }
  
  .zfb {
    .tb {
    margin-top:.25rem;
   }

   .xuanze {
    margin-top:.33rem;
   }

    
  }
  
}
.agreement{
  position: fixed;
  bottom: 1.8rem;
   width: 100%;
  text-align: center;
  font-size: .2rem;
      img {
            vertical-align: middle;
            width: .3rem;
            height: .3rem;
      }
      span {
         display: inline-block;
         margin-left:.09rem;
          color: #596277;
          line-height:.39rem;
      }
}
.footer {
  width: 100%;
  height: 1.31rem;
  box-shadow: -1px -1px 3px 0px #ADBFCE;
  position: fixed;
  bottom:0;
  left: 0;
  background-color:#EBEFF2;

  span {
    font-size: .43rem;
    color: #FF5232;
    display: inline-block;
     height: 1.31rem;
     line-height: 1.31rem;
     margin-left: .67rem;
  }

  .btn {
   float: right;
   margin-top:.21rem;
   margin-right: .4rem;
   width: 3.2rem;
   height: .91rem;
   border: .03rem solid #2775FF;
   border-radius: .45rem;
   font-size: .4rem;
   font-weight:400;
   color: #2775FF;
   line-height: .91rem;
   text-align: center;
  }
}


</style>